import { use, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setScreen, setIsBlurred } from '../../store/slices/walkthroughSlice';
import { setSound, setPhonems, setHash } from '../../store/slices/dataMessageSlice';
import { useTranslation } from 'next-i18next';
import { changeCameraTargetToNone } from '../../external/yord-santa/src/modules/slices/cameraSlice';
import { redirect } from 'next/dist/server/api-utils';
import Error from 'next/error'


export default function Screen_CZ_B_form({ onNext }) {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const inputRef = useRef('');
    const submitButtonRef = useRef(null);
    const customSelectsRef = useRef(null);


    const [badWords, setBadWords] = useState(false);

    const nameInputRef = useRef(null);

    const [nameInput, setNameInput] = useState('');
    const [nameError, setNameError] = useState('');

    const lang = useSelector(state => state.dataMessage.lang);

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [error, setError] = useState('');

    const [isCustomText, setIsCustomText] = useState(false);
    const [inputError, setInputError] = useState('');
    
    const [selectedMessage, setSelectedMessage] = useState(0);

    const [refetch, setRefetch] = useState(true);
    const [isFormSubmited, setIsFormSubmited] = useState(false);

    const [showHint, setShowHint] = useState( false );

    const [selectedGender, setSelectedGender] = useState(0); // Default value

    const handleGenderChange = (e) => {
      setSelectedGender(e.target.value);
    };

    const goNextPage = () => {
        dispatch(setScreen("C"));
    };

    let optionsCount = 21;
    if( lang == 'hu') { optionsCount = 21 }
    if( lang == 'sk') { optionsCount = 24 }
    if( lang == 'cs') { optionsCount = 23 }


    const selectOptions = Array.from({ length: optionsCount }, (_, index) => ({
        text: `Text: ${index}`,
        val: index,
      }));

    const handleCheckboxChange = (e) => {
        setAgreeTerms(e.target.checked);
        if (e.target.checked) {
          setError(''); // Clear the error if checked
        }
      };

      const validateInputRef = (value) => {
        if (!value || value.length < 4 || !/^[a-zA-ZáčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚŮÝŽöüóőúéáűíÖÜÓŐÚÉÁŰÍ\s.,'!?]*$/.test(value)) {
            setInputError(t('inputError.message'));
            return false;
        }
            setInputError('');
            return true;
        };

      const validateName = (name) => {

        if (!name || name.length > 16 || !/^[a-zA-ZáčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚŮÝŽöüóőúéáűíÖÜÓŐÚÉÁŰÍ\s]*$/.test(name) ) {
          setNameError('Name cannot be empty and must be 16 characters or less, containing only letters and spaces.');
          return false
        }
        setNameError('');
        return true
      };

    const handleCloseHint = () => {
        setShowHint( false );
    }

    const toggleHint = () => {
        setShowHint( !showHint )
    }

    

    useEffect(() => {
        customSelectsRef.current.focus();
      }, [showHint]);

      useEffect(() => {
        dispatch(changeCameraTargetToNone());
      }, []);

      const handleInputTextChange = () => {
        setIsCustomText(true);
    };

    const handleSelectClick = (data) => {
        inputRef.current.value = t(`message.messages.${ data.val }.text`);
        inputRef.current.setAttribute('data-option', data.val);

        if(validateInputRef( inputRef.current.value )) {

            setInputError('');
        }
        setSelectedMessage( inputRef.current.getAttribute('data-option') );
        setIsCustomText(false);
        toggleHint(false);
    } 

    let timer;

    const submitForm = (e) => {
        e.preventDefault();

        if (!agreeTerms) {
            setError( t('inputError.agree'));
            return
        }
        const isNameValid = validateName(nameInput);
        const isInputRefValid = validateInputRef(inputRef.current.value);
        
        if (!isNameValid || !isInputRefValid) {
            return;
        }
        
        if( isFormSubmited == false ) {
            submitButtonRef.current.className += ' loading';
            setBadWords(false);
            setIsFormSubmited( true );

            let i = 0;
            const getData = async ( data ) => {
                    const response = await fetch(`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/api/messages/${data.id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    
                    const audioData = await response.json();
                    
                    if( audioData.audioVectorUrl ) {
                        setRefetch(false);
                        clearInterval(timer);
                        dispatch(setSound(audioData.audioFileUrl));
                        dispatch(setPhonems(audioData.audioVectorUrl));
                        dispatch(setHash( data.id ));
                        getPhonems( audioData.audioVectorUrl );
                    }
                    else {
                        i++;

                    }
            }

            const getPhonems = async ( url ) => {
                    const phonemsArray = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    
                    const phonems = await phonemsArray.json();
                    
                    if( phonems ) {
                        dispatch(setPhonems( phonems ));
                        goNextPage();
                    }
                    else {
                        i++;

                    }
            }


            const postData = async () => {

                // const token = await executeRecaptcha("form_submit");
                
                const response = await fetch(`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/api/messages`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "textSelect1": parseInt(selectedGender),
                        "givenName": nameInput,
                        "textSelect2": parseInt(selectedMessage),
                        "textSelect2Custom": isCustomText ? inputRef.current.value : null,
                        "agree": true,
                        "lang": lang
                    }),
                });
                const result = await response.json();

               
                if( response.status == 500 ) {
                    // if( result["hydra:description"] == "Naughty bad words found"){
                        setBadWords(true);
                        submitButtonRef.current.className = 'btn btn-loading mb-6';
                        setIsFormSubmited( false );
                        return <Error statusCode={500} />
                    // }
                }
                if( result ) {
                    timer = setInterval(() => {
                        if (refetch) {
                            getData( result );
                        }
                    }, 1000);
                }
            };

            postData();
        }
          
        // e.preventDefault();
        // if (isValid) {
        //     dispatch(updateForm(form));
        //     onNext();
        // } else {
        //     console.log("is not valid");
        // }
    };

    
    

    return (
        <div className="screen_cs_form">
            <div className="lg:grid lg:grid-cols-[auto_1fr] lg:items-center lg:justify-center lg:h-full lg:min-h-[600px] max-w-[1920px] lg:mx-auto">
                <div className="screen_cs_form-content">
                    <h2 className='h1 text-left'>{ t('message.title') }</h2>
                    <p className='text text-left'>{ t('message.subtitle') }</p>
                    <form className='text-left' onSubmit={submitForm}>
                    { lang != 'hu' && 
                    <fieldset className='flex items-center justify-start gap-14 mb-6'>
                        <div className='custom-radio'>
                        <input
                            className='hidden'
                            type='radio'
                            id='male'
                            name='gender'
                            value={0}
                            checked={selectedGender == 0}
                            onChange={handleGenderChange}
                        />
                        <label htmlFor='male'>
                            <span className='checkbox-icon'></span>
                            <span>{t('message.gender.male')}</span>
                        </label>
                        </div>
                        <div className='custom-radio'>
                        <input
                            className='hidden'
                            type='radio'
                            id='female'
                            name='gender'
                            value={1}
                            checked={selectedGender == 1}
                            onChange={handleGenderChange}
                        />
                        <label htmlFor='female'>
                            <span className='checkbox-icon'></span>
                            <span>{t('message.gender.female')}</span>
                        </label>
                        </div>
                    </fieldset>
                    }

                    { lang == 'hu' &&
                        <div className='text-2xl mb-6'>{t('message.gender.male')}</div>
                    } 

                    <div className='flex flex-col items-start justify-start mb-4'>
                        <label htmlFor="name">{ t('message.name') }</label>
                        <input className='!mb-0' type="text" id="name" name="name" value={nameInput} onInput={e => setNameInput(e.target.value)} required/>
                        {nameError && <div className='error-message mt-2'>
                            <span className='error-icon'>
                            <svg
                                fill='#ffffff'
                                version='1.1'
                                id='Capa_1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                viewBox='0 0 45.311 45.311'
                                xmlSpace='preserve'
                            >
                                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                                <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                                <g id='SVGRepo_iconCarrier'>
                                <g>
                                    <path d='M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656 c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635 C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001 c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0 c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578 c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0 c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29 c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z'></path>
                                </g>
                                </g>
                            </svg>
                            </span>
                            <span>{nameError}</span>
                        </div>}
                    </div>

                    <p className='text-2xl mb-6'>{ t('message.message_start') }</p>

                    <div className='custom-textbox'>
                        <label htmlFor="message">{ t('message.input_label') }</label>
                        <div className='custom-textbox-inner'>
                            <input ref={inputRef} onKeyUp={handleInputTextChange} defaultValue='' type="text" id="message" name="message" required />
                            <span className='show-hint-button gap-2 flex items-center justify-start' onClick={ toggleHint }>
                            { t('message.message_hint') }
                            <svg width="18" height="10" viewBox="0 0 44 27" fill="#3B2774" xmlns="http://www.w3.org/2000/svg">
                                <path d="M42 2.7063L22 23.2016L2 2.7063" stroke="transparent" strokeWidth="4" strokeLinecap="round"/>
                            </svg>

                            </span>
                            <div tabIndex="0" onBlur={handleCloseHint} ref={customSelectsRef} className={`custom-textbox-selects${showHint ? '' : ' hidden'}`}>
                                <ul>
                                {selectOptions.map(function(data) {
                                    return (
                                        <li onClick={ () => handleSelectClick(data) } key={data.val} data-val={ data.val }>{ t(`message.messages.${ data.val }.text`) }</li>
                                    )
                                    })}                                    
                                </ul>
                            </div>
                        </div>
                        {inputError && (
                        <div className="error-message mt-2">
                            <span className="error-icon">
                            <svg
                                    fill='#ffffff'
                                    version='1.1'
                                    id='Capa_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 45.311 45.311'
                                    xmlSpace='preserve'
                                >
                                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                                    <g id='SVGRepo_iconCarrier'>
                                    <g>
                                        <path d='M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656 c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635 C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001 c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0 c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578 c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0 c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29 c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z'></path>
                                    </g>
                                    </g>
                                </svg>
                            </span>
                            <span>{inputError}</span>
                        </div>
                        )}
                    </div>
                        

                        <p className="text-2xl mb-6">{ t('message.message_end') }</p>
                        <p className='text-2xl  mb-10'>{ t('message.message_greetings') }</p>
                        
                        <div className='custom-checkbox mb-4'>
                           <input
                                className='hidden'
                                type='checkbox'
                                id='terms'
                                name='terms'
                                checked={agreeTerms}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor='terms'>
                            {t('message.terms')} <a target='_blank' href={'/pdf/' + t('message.terms_file')}>{t('message.conditions')}.</a>
                            </label>
                            {error && <div className='error-message'>
                                <span className='error-icon'>
                                <svg
                                    fill='#ffffff'
                                    version='1.1'
                                    id='Capa_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    viewBox='0 0 45.311 45.311'
                                    xmlSpace='preserve'
                                >
                                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                                    <g id='SVGRepo_iconCarrier'>
                                    <g>
                                        <path d='M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656 c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635 C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001 c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0 c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578 c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0 c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29 c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z'></path>
                                    </g>
                                    </g>
                                </svg>
                                </span>
                                <span>{error}</span>
                            </div>}
                        </div>
                        <button ref={submitButtonRef} className="btn btn-loading mb-6" type="submit">
                            { t('message.button_text') }
                        </button>
                        { badWords && <div className='error-message'><span className='error-icon'>
                            <svg
                                fill='#ffffff'
                                version='1.1'
                                id='Capa_1'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                viewBox='0 0 45.311 45.311'
                                xmlSpace='preserve'
                            >
                                <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                                <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                                <g id='SVGRepo_iconCarrier'>
                                <g>
                                    <path d='M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656 c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635 C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001 c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0 c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578 c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0 c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29 c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z'></path>
                                </g>
                                </g>
                            </svg>
                            </span><span dangerouslySetInnerHTML={{__html: t('inputError.profanity', {interpolation: {escapeValue: false}})}}></span></div>}

                        
                    </form>
                </div>
                <div className="self-end max-lg:hidden lg:flex lg:h-full lg:max-h-[1080px] overflow-hidden">
                    <img className="object-cover object-left-bottom h-full w-full" src="/images/santa-big.png" />
                </div>
            </div>
        </div>
        // <div className="screen_cz_b_form">
        //     <div className="main">
        //         <div className="main-bg"></div>
        //         <div className="main-wrapper">
        //             <h2>WHAT'S IN YOUR HEART, MILKULÁŠ?</h2>
        //             <p className="text-big">Write your praise and create a video</p>

        //             <form onSubmit={submitForm}>
        //                 <TextInput
        //                     id="name"
        //                     label="Name"
        //                     value={form.name}
        //                     onChange={(value) => handleChange('name', value)}
        //                     onValidated={(isValidField) => setValid('name', isValidField)}
        //                     required={true}
        //                 />

        //                 <SuperCoolInput />

        //                 <p className="text-big">I’ve heard you deserve praise for your good deeds! Especially for</p>

        //                 <button className="btn" type="submit">
        //                     Send message
        //                 </button>
        //             </form>
        //         </div>
        //     </div>
        // </div>
    );
}
